import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      <div className="footerheader">
<div className="footerlogo">
  <img src="https://yourgutmap.co.uk/wp-content/uploads/2023/03/ygm-logo.png" alt="" />
</div>
<div className="lista">
  <h1>About</h1>
  <ul>
<li>Microbiome</li>
<li>Food sensitvity</li>
<li>science</li>
<li>Shop</li>

  </ul>
</div>
<div className="lista">

<h1>Connect</h1>
<ul>

<li>Contact</li>
<li>Practitioners</li>

</ul>
</div>
      <div className="lista">
<h1>Address</h1>
<ul>

<li>yourgutMap</li>
<li>The Catalyst</li>
<li>York Science Park</li>
<li>York</li>
<li>YO1O5GA</li>

</ul>
      </div>
      </div>
<div className="footerend">
<div className='footerendleft'>
<a href="">Privacy Policy</a>
<a href="">Term & condition</a>


</div>
<div className="footerendright">
<p>YourGutMap Copyright 2024-25 © All rights Reserved.</p>
</div>

</div>


    </div>
  )
}

export default Footer
