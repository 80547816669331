import React, { useEffect, useState } from 'react';
import Header from '../component/header';
import Latestarticles from '../component/latestarticles';
import Toparticles from '../component/toparticles';
import Footer from '../component/footer';
import { useNavigate, useParams } from 'react-router-dom'
import ticketpage from './ticketpage';
import Searchbarpage from './searchbarpage';
import { AutoComplete } from 'antd';
import { SiLibreofficewriter } from 'react-icons/si';
import { TfiWrite } from 'react-icons/tfi';
import { BsQuestionCircle } from 'react-icons/bs';
import { PiSealQuestionFill } from 'react-icons/pi';

function Searchhh() {
  const search = useNavigate()


  const { id } = useParams();





  const navigate = useNavigate()





  const ali = useNavigate()



  const [options, setOptions] = useState([]);
  const [questions, setQuestions] = useState([]);


  const [questionsnn, setQuestionsnn] = useState([]);
  // Fetch questions (FAQs)
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallfaq", requestOptions)
      .then(response => response.json())
      .then(result => {
        const filteredQuestions = result
          .filter(item => item.question.toLowerCase().includes(id.toLowerCase()))


        setQuestionsnn(filteredQuestions);


      })
      .catch(error => console.log('error', error));
  }, []);

  // Handle search (filter questions)
  const handleSearch = (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }

    // Filter the questions that include the search text
    const filteredQuestions = questions
      .filter(item => item.question.toLowerCase().includes(searchText.toLowerCase()))
      .map(item => ({
        value: item.question,
        label: item.question
      }));

    setOptions(filteredQuestions);
  };

  const [searchvalue, setsearchvalue] = useState("")

  const [open, setopen] = useState("")
  return (
    <div className='mainpage'>

      <div className='heading'>
        <h2>Hello, how can we help?</h2>
      </div>
      <form className='searchbox' onSubmit={(e) => {
        e.preventDefault()
        ali("/search/" + searchvalue)
      }}>

        <AutoComplete
          options={options}
          style={{ width: 200 }}
          placeholder="Search for article"

          className='autocomple'
          onSearch={handleSearch}  // When typing, call handleSearch
          onSelect={(value) => console.log(value)}  // Handle selection
          onChange={(e) => setsearchvalue(e)}
        />
      </form>




      <div className='buttondiv'>
        <div className='buttonhead'>
          <div className='iconbox'><BsQuestionCircle style={{ width: '35px', height: "35px" }} /></div>
          <h3>FAQs</h3>
        </div>

      </div>

      <div className='toparticlesmaindiv'>

        {questionsnn.map((value, index) => {
          return (<>
            <div className='articlesdiv'  style={{ cursor: 'pointer' }}>
              <div className='iconbox'><PiSealQuestionFill style={{ width: '35px', height: "35px" }} /></div>
              <div className='articlestext' >
                <span>
                  <h3>{value.question}</h3>



                  <p>

                    <span dangerouslySetInnerHTML={{ __html: value.answer.replace(/\n/g, "<br />") }} />

                  </p>

                </span>



              </div>
            </div>
          </>)
        })}

      </div>


    </div>
  );
}

export default Searchhh;
