import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function Tokenpage() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [tickets, settickets] = useState([])
  const [reply, setreply] = useState("")

  useEffect(() => {
    const intervalId = setInterval(() => {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("ticketID", id);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallticketsbyticketID", requestOptions)
        .then(response => response.json())
        .then(result => settickets(result))
    }, 1000); // Runs every 1 second
    return () => clearInterval(intervalId);
  }, [])

  return (
    <div>
      <div className="toparticlesfirstpagemaindiv">
        <div className='toparticlesfirstpageleft'>




          {tickets.length !== 0 && <>
            <h3>{tickets.subject}</h3>
            <p style={{ display: 'flex', textAlign: 'left' }}>

              <span dangerouslySetInnerHTML={{ __html: tickets.Message.replace(/\n/g, "<br />") }} />
            </p>





            <div className='header' style={{ marginTop: '50px' }} >
              <h2>Replies </h2>
            </div>

            {tickets.reply.map((value) => {
              return (<>

                {value.replyby === "admin" ? (<>
                  <div style={{ display: 'flex', justifyContent: "left", width: '100%', }}>
                    <div class="sharp-corner-box">
                      <p style={{ display: 'flex', textAlign: 'left' }}>

                        <span dangerouslySetInnerHTML={{ __html: value.contant.replace(/\n/g, "<br />") }} />
                      </p>
                    </div>
                    <div style={{ color: "gray" }}>{value.date}</div>
                  </div> </>) : (<>
                    <div style={{ display: 'flex', justifyContent: "right", width: '100%', }}>
                      <div style={{ color: "gray" }}>{value.date}</div>
                      <div class="sharp-corner-box2">
                        <p style={{ display: 'flex', textAlign: 'left' }}>

                          <span dangerouslySetInnerHTML={{ __html: value.contant.replace(/\n/g, "<br />") }} />
                        </p>
                      </div>
                    </div>
                  </>)}
              </>)
            })}



            {tickets.status === "open" ? (<>
              <div style={{ marginTop: '50px' }}></div>
              <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', marginl: '0px', padding: '0px' }}>Reply : </h4>
              <form onSubmit={async (e) => {
                e.preventDefault()
                const hide = message.loading("Action in progress", 0)

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("_id", tickets._id);
                urlencoded.append("contant", reply);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };


                await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/replybyuser", requestOptions)
                  .then(response => response.json())
                  .then(result => {
                    settickets(result)
                    setreply("")
                  })
                  .catch(error => console.log('error', error));


                await setTimeout(() => {
                  hide(); // Call hide to stop the loading message
                  message.success("Action completed successfully");
                }, 2000);

              }} style={{ width: '100%', padding: '0', display: 'flex', flexDirection: 'column', gap: '10px' }}>  <textarea value={reply} onChange={(e) => setreply(e.target.value)} required style={{ width: '100%', height: '100px', backgroundColor: '#D2CDFF' }} placeholder='Type your Replay' /> <button className='button'>Send</button></form>
            </>) : (<>

              <p style={{ color: 'gray' }}>Ticket is Closed by Admin</p>
            </>)}




          </>}
        </div>
        <div className='toparticlesfirstpageright'>
          <div className="articlesdetailbox">
            <h4>Ticket Details</h4>
            <span><p>Ticket ID:</p> <p>{tickets.ticketID}</p></span>
            <span><p>category:</p> <a href="">
              Knowledgebase</a></span>


            <span><p>Date :</p><p>{tickets.timestamp}</p></span>
            <span><p>status:</p> <p>{tickets.status}</p></span>
            {/* <span><p>rated(votes):</p> <p>
              &#9733;
              &#9733;
              &#9733;
              &#9734;
              &#9734;(1,251)</p></span> */}
            <div className='backbutton'>

              <a href="" >Go back</a>
            </div>
          </div>




        </div>
      </div>
    </div>
  )
}

export default Tokenpage
