import React from 'react'
import { useNavigate } from 'react-router-dom'

function Toparticlessecendpage() {
  return (
    <div>
      
    </div>
  )
}

export default Toparticlessecendpage
