import { message } from 'antd'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


function Form() {

  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [priority, setpriority] = useState("low")
  const [subject, setsubject] = useState("")
  const [messagee, setmessagee] = useState("")
  const [attachment, setattachment] = useState()
  const { id } = useParams();
  const navigate = useNavigate()


  const senddata = async (e) => {
    e.preventDefault()
    const hide = message.loading("Action in progress", 0)

    console.log(attachment)
    const imgRef = ref(imageDb, `ticketfiles/${attachment.name}`)
    await uploadBytes(imgRef, attachment)
    const cvUrl = await getDownloadURL(imgRef);



    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("name", name);
    urlencoded.append("email", email);
    urlencoded.append("priority", priority);
    urlencoded.append("subject", subject);
    urlencoded.append("Message", messagee);
    urlencoded.append("category", id);
    urlencoded.append("attachment", cvUrl);


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addtickets", requestOptions)
      .then((response) => response.text())
      .then((result) => navigate("/"))
      .catch((error) => console.error(error));


    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Ticket Submitted");
    }, 2000);
  }


  return (
    <div className='formpage'>
      <form action="" onSubmit={senddata}>

        <label htmlFor="">Name:*</label>
        <input type="username" required value={name} onChange={(e) => setname(e.target.value)} />

        <label htmlFor="">Email:*</label>
        <input type="email" required value={email} onChange={(e) => setemail(e.target.value)} />


        <div className='dropdown'>
          <label>priority:</label>
          <select className='priorty' required value={priority} onChange={(e) => setpriority(e.target.value)}>
            <option selected value={"law"}> law</option>
            <option value={"medium"}> medium</option>
            <option value={"high"}> high </option>
          </select>
        </div>
        <label htmlFor="">subject:*</label>
        <input type="text" required value={subject} onChange={(e) => setsubject(e.target.value)} />

        <label htmlFor="">Message*</label>
        <textarea name="" id="" required value={messagee} onChange={(e) => setmessagee(e.target.value)} />

        <label htmlFor="">attachment:</label>
        <input type="file" className='file' onChange={(e) => setattachment(e.target.files[0])} />

        <input type='submit' />
      </form>
    </div>
  )
}

export default Form
