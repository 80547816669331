import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Toparticlesfirstpage from './toparticlesfirstpage'
import { PiSealQuestionFill } from 'react-icons/pi'
function Toparticles() {


  const [open, setopen] = useState("")
  const [question, setquestion] = useState([])

  useEffect(() => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallfaq", requestOptions)
      .then(response => response.json())
      .then(result => setquestion(result))


  }, [])

  return (
    <div className='toparticlesmaindiv'>

      {question.map((value, index) => {
        return (<>
          <div className='articlesdiv' onClick={() => { setopen(index) }} style={{cursor:'pointer'}}>
            <div className='iconbox'><PiSealQuestionFill style={{width:'35px',height:"35px"}}/></div>
            <div className='articlestext' >
              <span>
                <h3>{value.question}</h3>

                {open === index && <>

                  <p>

                    <span dangerouslySetInnerHTML={{ __html: value.answer.replace(/\n/g, "<br />") }} />

                  </p>
                </>}
              </span>



            </div>
          </div>
        </>)
      })}

    </div>
  )
}

export default Toparticles



