import React from 'react'


function Toparticlesfirstpage(){




  return (
    <div className='toparticlesfirstpage'>

        <div className='searchbox'>
          <input type="text" placeholder='Search for article' />
        </div>
      
    </div>
  )
}

export default Toparticlesfirstpage
