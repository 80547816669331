import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'


function Viewtickets() {


  const token = useNavigate()
  const Gotonext = () => {
    token("/tracking/" + ticketid)
  }

  const [ticketid, setticketid] = useState("")


  return (
    <div className='viewtcketspage'>
      <div className='viewticketsmaindiv'>
        <label>Ticket tracking id:*</label>

       <form style={{display:'flex',flexDirection:'column',gap:'10px'}} onSubmit={Gotonext}> <input type='text' required value={ticketid} onChange={(e) => setticketid(e.target.value)} />
        <button >Submit</button>
        </form>
        <a href=''>Forget tracking ID?</a>
      </div>
    </div>
  )
}

export default Viewtickets
