import React from 'react'
import { FaAdversal } from 'react-icons/fa'
import { FaMoneyBillTransfer } from 'react-icons/fa6'
import { IoIosCopy } from 'react-icons/io'
import { MdSupportAgent } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
// import Form from './form'


function Ticketpage() {


    const form = useNavigate()
    const Gotoform = (value) =>{
        form("/Form/"+value)
}


    return (
        <div className='ticketpage'>
            <h2>what can we help with?</h2>
            <div className='mainbox'>
                <div className='mainbox1' onClick={()=>Gotoform("General")}>
                    <div className='iconbox'> <IoIosCopy style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>General</h4>
                </div>
                <div className='mainbox1' onClick={()=>Gotoform("support")}><div className='iconbox'><MdSupportAgent style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>support</h4></div>
                <div className='mainbox1' onClick={()=>Gotoform("adversting")}><div className='iconbox'><FaAdversal style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>adversting</h4></div>
                <div className='mainbox1' onClick={()=>Gotoform("billing")}><div className='iconbox'><FaMoneyBillTransfer style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>billing</h4></div>
            </div>
        </div>
    )
}

export default Ticketpage
