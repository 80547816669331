import React from 'react'

function Searchbarpage() {
  return (
    <div className='searchmaindiv'>
      <p>hdgfhjfdshjfdshjfdshjfdshfdgshf</p>
    </div>
  )
}

export default Searchbarpage