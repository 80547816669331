import logo from './logo.svg';
import './App.css';
import Header from './component/header';
import Navbar from './component/navbar';
import Toparticles from './component/toparticles';
import { useState } from 'react';
import Latestarticles from './component/latestarticles';
import Footer from './component/footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Index from './pages/index';
import Ticketpage from './pages/ticketpage';
import Form from './pages/form';
import Toparticlesfirstpage from './component/toparticlesfirstpage';
import Toparticlessecendpage from './component/toparticlessecendpage';
import Toparticlethirdpage from './component/toparticlethirdpage';
import Toparticlesfourthpage from './component/toparticlesfourthpage';
import Viewtickets from './component/viewtickets';
import Searchbarpage from './pages/searchbarpage';
import Tokenpage from './component/tokenpage';
import Searchhh from './pages/search';
function App() {




  return (
    <div className="App">

      {/* <Header /> */}
      <Navbar />

      <BrowserRouter>
        <Routes>

          <Route path='/tracking/:id' element={<><Tokenpage /></>} />

          <Route path='/viewtickets' element={<><Viewtickets /></>} />

          <Route path='/searching' element={<><Searchbarpage /></>} />

          <Route path='/Firstpage' element={<><Toparticlesfirstpage /></>} />

          <Route path='/secendpage' element={<><Toparticlessecendpage /></>} />

          <Route path='/thirdpage' element={<><Toparticlethirdpage /></>} />

          <Route path='/fourthpage' element={<><Toparticlesfourthpage /></>} />

          <Route path='/form/:id' element={<><Form /></>} />
          <Route path='/search/:id' element={<><Searchhh /></>} />
          <Route path='/ticket' element={<><Ticketpage /></>} />
          <Route path='/' element={<> <Index /></>} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
